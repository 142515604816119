<template>
  <vue-tel-input-vuetify
    name="phone"
    v-model="phone"
    defaultCountry="fr"
    :only-countries="['fr', 'de', 'pl', 'es', 'be', 'nl', 'lu', 'it', 'us', 'ca']"
    @input="onInput"
    :rules="rules"
    :error="!phone_valid"
    :label="label"
    :placeholder="placeholder"
    :dense="dense"
    :outlined="outlined"
    :hint="hint"
    :persistent-hint="!!hint"
  ></vue-tel-input-vuetify>
</template>

<script>
export default {
  components: {
    VueTelInputVuetify: () => import("vue-tel-input-vuetify/lib/vue-tel-input-vuetify.vue"),
  },
  props: ["value", "label", "rules", "placeholder", "dense", "outlined", "hint"],
  data() {
    return {
      phone: this.value,
      phone_valid: true,
    };
  },
  watch: {
    value() {
      if (this.value !== this.phone) {
        this.phone = this.value;
      }
    },
  },
  methods: {
    onInput(formattedNumber, { country, valid }) {
      this.phone_valid = valid;
      const formatted_phone = this.phone
        .toString()
        .replace(/[^0-9+]/g, "")
        .replace(/^0/, `+${country.dialCode}`)
        .replace(/^6/, `+${country.dialCode}6`)
        .replace(/^7/, `+${country.dialCode}7`);
      this.$emit("input", formatted_phone);
    },
  },
};
</script>
<style>
.vue-tel-input-vuetify .country-code .v-text-field--outlined fieldset {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.vue-tel-input-vuetify > .v-text-field--outlined fieldset {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
</style>
